import * as React from 'react'

function SvgFacebook(props) {
	return (
		<svg width={7} height={12} {...props}>
			<path
				d='M4.928 1.992h1.096V.085A14.146 14.146 0 004.428 0c-1.58 0-2.662.994-2.662 2.82V4.5H.023v2.133h1.743V12h2.137V6.633h1.673L5.84 4.5H3.903V3.031c0-.617.166-1.039 1.025-1.039z'
				fill='#ffffff'
				fillRule='nonzero'
			/>
		</svg>
	)
}

export default SvgFacebook
