import * as React from 'react'

function SvgGoogle(props) {
	return (
		<svg width={12} height={12} {...props}>
			<path
				d='M5.965 2.813a3.19 3.19 0 00-2.704 1.559c-.281.47-.444 1.02-.448 1.605v.046A3.189 3.189 0 007.63 8.74c.514-.305.94-.754 1.216-1.304H6V4.625h5.849l.053.288c.065.356.098.722.098 1.087a5.96 5.96 0 01-1.757 4.243A5.955 5.955 0 016 12h-.048a5.96 5.96 0 01-4.195-1.757A5.955 5.955 0 010 6c0-1.338.435-2.609 1.238-3.651.159-.207.332-.404.52-.592A5.96 5.96 0 016 0h.048a5.96 5.96 0 014.195 1.757l.248.249-1.989 1.989-.248-.249A3.167 3.167 0 006 2.813h-.035z'
				fill='#ffffff'
				fillRule='nonzero'
			/>
		</svg>
	)
}

export default SvgGoogle
